// tree-shaking the fontawesome
import { fa } from 'vuetify/iconsets/fa-svg'
import { library, config } from '@fortawesome/fontawesome-svg-core' // Core SVG
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// fa free solid imports
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

// fa pro solid imports
// import { } from "@fortawesome/pro-solid-svg-icons";

// fa regular imports
// import {  } from "@fortawesome/pro-regular-svg-icons";

// fa pro light imports
import {
  faBars,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faExclamationTriangle,
  faFilePdf,
  faGlobe,
  faHouse,
  faMagnifyingGlass,
  faScrewdriverWrench,
} from '@fortawesome/pro-light-svg-icons'

// add all imported icons to library
library.add(
  faBars,
  faCheck,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faExclamationTriangle,
  faFilePdf,
  faGlobe,
  faHouse,
  faMagnifyingGlass,
  faScrewdriverWrench,
)

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// vuetify icon aliases
const aliases = {
  bars: 'fal fa-bars',
  caretDown: 'fas fa-caret-down',
  check: 'fal fa-check',
  chevronLeft: 'fal fa-chevron-left',
  chevronRight: 'fal fa-chevron-right',
  envelope: 'fal fa-envelope',
  exclamationTriangle: "fal fa-exclamation-triangle",
  filePdf: 'fal fa-file-pdf',
  globe: 'fal fa-globe',
  house: 'fal fa-house',
  magnifyingGlass: 'fal fa-magnifying-glass',
  screwdriverWrench: 'fal fa-screwdriver-wrench',
}

// export for nuxt
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
  nuxtApp.hook('vuetify:configuration', ({ vuetifyOptions }) => {
      vuetifyOptions.icons = {
          defaultSet: 'fa',
          aliases: aliases,
          sets: {
              fa,
          }
      }
  })
})
