import revive_payload_client_4sVQNw7RlN from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_i18n_ftx86CUdf5 from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_RMzWu406ID from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import plugin_client_LcKgStRyi6 from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errorHandler_cfkZ75xwSQ from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/plugins/errorHandler.js";
import fontAwesome_ZfwYhjRo2I from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/plugins/fontAwesome.js";
import vuetify_sync_XQ085wXJDC from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-sync.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_i18n_ftx86CUdf5,
  vuetify_icons_RMzWu406ID,
  plugin_client_LcKgStRyi6,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  errorHandler_cfkZ75xwSQ,
  fontAwesome_ZfwYhjRo2I,
  vuetify_sync_XQ085wXJDC
]