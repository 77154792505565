<template>
  <div>
    <v-app-bar app color="secondary" elevation="1" dark>
      <v-row>
        <v-col>
          <v-toolbar-title class="text-white mt-n6">
            <NuxtLink :to="localePath('/')">
              <GhgCalculatorIconAlt
                class="ml-4 mr-2 ghg-toolbar-icon"
                height="48"
                width="48"
                max0height="48"
                max-width="48"
              ></GhgCalculatorIconAlt>
            </NuxtLink>
              {{ $t(`appInfo.appTitle`) }}
          </v-toolbar-title>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <!-- sm and smaller screens get menu bars and dropdown -->
      <template v-if="smAndDown">
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn icon="$bars" v-bind="props"></v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in navItems"
                @click="navigate(item[0])"
                :key="i"
              >
                <v-list-item-title>{{ item[1] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
      <!-- larger screens show all nav items -->
      <template v-else>
      <v-btn
        v-for="item in navItems"
        :key="item[0]"
        variant="flat"
        @click="navigate(item[0])"
        class="mr-2"
        color="secondary"
        flat
      >
        {{ item[1] }}
      </v-btn>
      </template>
      <!-- todo add locale switcher and fr language content -->
      <!-- <locale-switcher class="mb-0 mx-2 pb-0"></locale-switcher> -->
    </v-app-bar>
  </div>
</template>
<script setup lang="ts">
import GhgCalculatorIconAlt from "@/assets/images/ghg_calculator_icon_alt.svg?component";
import { useDisplay } from 'vuetify'
// import { useUserStore } from "~~/stores/user";

const localePath = useLocalePath();
const localeRoute = useLocaleRoute();
const { t, locale, setLocale } = useI18n();

// todo add locale switcher and fr language content
// check for user locale preferences when first loading
// onBeforeMount(() => {
//   if (!['', null, undefined].includes(useUserStore().getCurrentLocale) && locale.value !== useUserStore().getCurrentLocale) {
//     const newLocale = useUserStore().getCurrentLocale
//     const switchLocalePath = useSwitchLocalePath();
//     setLocale(newLocale);
//     navigateTo(switchLocalePath(newLocale));
//   }
// })

const { smAndDown } = useDisplay();

// get ordered list of navigation items in current locale to show in header
const getNavItems = () => {
  return [
    // paths
    "documentation",
    "customization",
    "support",
  ].map((item) => [
    item,
    // lookup translations/titles for each path from locale files
    t(`navigation.routeTitles.${item}`),
  ]);
};
const navItems = ref(getNavItems());

// watch for changes to locale
// watch(
//   () => locale.value,
//   () => {
//     navItems.value = getNavItems();
//   },
//   { immediate: true }
// );

// navigate to a page
const navigate = (name: string) => {
  const route = localeRoute({ name: name });
  if (route) {
    return navigateTo(route.fullPath);
  }
};
</script>
<style lang="scss">
@use "./settings";
#locale-switcher {
  max-width: 140px;
  float: right;
  max-height: 3em;
}
</style>
<style lang="scss" scoped>
.ghg-toolbar-icon {
  transform: translate(0, 16px);
}
</style>
