<template>
  <div>
    <v-app>
      <NuxtLayout>
        <HeaderMain></HeaderMain>
        <NuxtPage id="page-container" />
        <FooterMain></FooterMain>
      </NuxtLayout>
    </v-app>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - GHG Calculator for Municipal Projects` : 'GHG Calculator for Municipal Projects';
  }
})
</script>
<style lang="scss">
@use "./settings";
#page-container {
  margin-top: 120px;
  margin-bottom: 200px;
}
</style>
