

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"customTheme","themes":{"customTheme":{"dark":false,"colors":{"primary":"#203564","secondary":"#17b1dc","accent":"#E3F2FD","error":"#B71C1C","caution":"#FDD835","warning":"#BF360C","success":"#73BF4D","lightBg":"#ECEFF1","greyText":"#455A64"}}}},"ssr":true}')
  
  
  
  
  return options
}

