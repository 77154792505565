import { default as indextItQlJympwMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/about/index.vue?macro=true";
import { default as indexfkokrv7RNjMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/contact/index.vue?macro=true";
import { default as indexLLj1LiILHwMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/customization/index.vue?macro=true";
import { default as indexSdabJj32OSMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/documentation/index.vue?macro=true";
import { default as indexxSawldbaWcMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/index.vue?macro=true";
import { default as indexowtqDgRcbZMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/privacy/index.vue?macro=true";
import { default as indexmvqTdTQIpyMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/support/index.vue?macro=true";
import { default as indexHIcZ9kmsfNMeta } from "/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/terms/index.vue?macro=true";
export default [
  {
    name: indextItQlJympwMeta?.name ?? "about___en___default",
    path: indextItQlJympwMeta?.path ?? "/about",
    meta: indextItQlJympwMeta || {},
    alias: indextItQlJympwMeta?.alias || [],
    redirect: indextItQlJympwMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indextItQlJympwMeta?.name ?? "about___en",
    path: indextItQlJympwMeta?.path ?? "/en/about",
    meta: indextItQlJympwMeta || {},
    alias: indextItQlJympwMeta?.alias || [],
    redirect: indextItQlJympwMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexfkokrv7RNjMeta?.name ?? "contact___en___default",
    path: indexfkokrv7RNjMeta?.path ?? "/contact",
    meta: indexfkokrv7RNjMeta || {},
    alias: indexfkokrv7RNjMeta?.alias || [],
    redirect: indexfkokrv7RNjMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexfkokrv7RNjMeta?.name ?? "contact___en",
    path: indexfkokrv7RNjMeta?.path ?? "/en/contact",
    meta: indexfkokrv7RNjMeta || {},
    alias: indexfkokrv7RNjMeta?.alias || [],
    redirect: indexfkokrv7RNjMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexLLj1LiILHwMeta?.name ?? "customization___en___default",
    path: indexLLj1LiILHwMeta?.path ?? "/customization",
    meta: indexLLj1LiILHwMeta || {},
    alias: indexLLj1LiILHwMeta?.alias || [],
    redirect: indexLLj1LiILHwMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/customization/index.vue").then(m => m.default || m)
  },
  {
    name: indexLLj1LiILHwMeta?.name ?? "customization___en",
    path: indexLLj1LiILHwMeta?.path ?? "/en/customization",
    meta: indexLLj1LiILHwMeta || {},
    alias: indexLLj1LiILHwMeta?.alias || [],
    redirect: indexLLj1LiILHwMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/customization/index.vue").then(m => m.default || m)
  },
  {
    name: indexSdabJj32OSMeta?.name ?? "documentation___en___default",
    path: indexSdabJj32OSMeta?.path ?? "/documentation",
    meta: indexSdabJj32OSMeta || {},
    alias: indexSdabJj32OSMeta?.alias || [],
    redirect: indexSdabJj32OSMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/documentation/index.vue").then(m => m.default || m)
  },
  {
    name: indexSdabJj32OSMeta?.name ?? "documentation___en",
    path: indexSdabJj32OSMeta?.path ?? "/en/documentation",
    meta: indexSdabJj32OSMeta || {},
    alias: indexSdabJj32OSMeta?.alias || [],
    redirect: indexSdabJj32OSMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/documentation/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSawldbaWcMeta?.name ?? "index___en___default",
    path: indexxSawldbaWcMeta?.path ?? "/",
    meta: indexxSawldbaWcMeta || {},
    alias: indexxSawldbaWcMeta?.alias || [],
    redirect: indexxSawldbaWcMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSawldbaWcMeta?.name ?? "index___en",
    path: indexxSawldbaWcMeta?.path ?? "/en",
    meta: indexxSawldbaWcMeta || {},
    alias: indexxSawldbaWcMeta?.alias || [],
    redirect: indexxSawldbaWcMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexowtqDgRcbZMeta?.name ?? "privacy___en___default",
    path: indexowtqDgRcbZMeta?.path ?? "/privacy",
    meta: indexowtqDgRcbZMeta || {},
    alias: indexowtqDgRcbZMeta?.alias || [],
    redirect: indexowtqDgRcbZMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexowtqDgRcbZMeta?.name ?? "privacy___en",
    path: indexowtqDgRcbZMeta?.path ?? "/en/privacy",
    meta: indexowtqDgRcbZMeta || {},
    alias: indexowtqDgRcbZMeta?.alias || [],
    redirect: indexowtqDgRcbZMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexmvqTdTQIpyMeta?.name ?? "support___en___default",
    path: indexmvqTdTQIpyMeta?.path ?? "/support",
    meta: indexmvqTdTQIpyMeta || {},
    alias: indexmvqTdTQIpyMeta?.alias || [],
    redirect: indexmvqTdTQIpyMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmvqTdTQIpyMeta?.name ?? "support___en",
    path: indexmvqTdTQIpyMeta?.path ?? "/en/support",
    meta: indexmvqTdTQIpyMeta || {},
    alias: indexmvqTdTQIpyMeta?.alias || [],
    redirect: indexmvqTdTQIpyMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexHIcZ9kmsfNMeta?.name ?? "terms___en___default",
    path: indexHIcZ9kmsfNMeta?.path ?? "/terms",
    meta: indexHIcZ9kmsfNMeta || {},
    alias: indexHIcZ9kmsfNMeta?.alias || [],
    redirect: indexHIcZ9kmsfNMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexHIcZ9kmsfNMeta?.name ?? "terms___en",
    path: indexHIcZ9kmsfNMeta?.path ?? "/en/terms",
    meta: indexHIcZ9kmsfNMeta || {},
    alias: indexHIcZ9kmsfNMeta?.alias || [],
    redirect: indexHIcZ9kmsfNMeta?.redirect,
    component: () => import("/Users/noah/Documents/websites/ghg-assessment-tool-landing-page/pages/terms/index.vue").then(m => m.default || m)
  }
]