<template>
  <v-footer class="bg-grey-lighten-2 mt-16 py-16" height="100" app>
    <v-row class="ml-16 mt-n12">
      <v-btn
        v-for="item in navItems"
        :key="item[0]"
        variant="text"
        @click="navigate(item[0])"
        class="mx-1"
        flat
      >
        {{ item[1] }}
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <NuxtLink
            to="https://ssg.coop"
            target="_blank"
            title="Sustainability Solutions Group"
          >
        <v-img
          src="/images/ssg-logo.png"
          alt="Sustainability Solutions Group"
          width="auto"
          max-height="40"
          max-width="400"
          contain
        ></v-img>
        </NuxtLink>
      </v-col>
      <v-col cols="12">
        <p class="text-caption mr-8 text-right">
          Copyright © 2023
          <NuxtLink
            to="https://ssg.coop"
            title="Sustainability Solutions Group"
            target="_blank"
            >Sustainability Solutions Group</NuxtLink
          >
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script setup lang="ts">
const localeRoute = useLocaleRoute();
const { t, locale, setLocale } = useI18n();

// get ordered list of navigation items in current locale to show in header
const getNavItems = () => {
  return [
    // paths
    "contact",
    "privacy",
    "terms",
  ].map((item) => [
    item,
    // lookup translations/titles for each path from locale files
    t(`navigation.routeTitles.${item}`),
  ]);
};
const navItems = ref(getNavItems());

// watch for changes to locale
watch(
  () => locale.value,
  () => {
    navItems.value = getNavItems();
  },
  { immediate: true }
);

// navigate to a page
const navigate = (name: string) => {
  const route = localeRoute({ name: name });
  if (route) {
    return navigateTo(route.fullPath);
  }
};
</script>
<style lang="scss">
@use "./settings";
</style>
